import useOutsideClickDetect from '@lib/hooks/useOutsideClickDetect';
import React, { useRef, useState } from 'react';
import CheckboxComponent from './Checkbox.new';

type DropdownTypes = 'item' | 'checkbox';

interface ItemProps {
  children: React.ReactNode;
  value: string;
  addonLeft?: React.ReactNode;
  addonRight?: React.ReactNode;
  addonRightMost?: React.ReactNode;
  additionalInfo?: string;
  disabled?: boolean;
}

interface CheckboxProps {
  children: React.ReactNode;
  value: string;
  addonRight?: React.ReactNode;
  addonRightMost?: React.ReactNode;
  additionalInfo?: string;
  disabled?: boolean;
}
interface DropdownProps {
  trigger:
  | string
  | ((props: { onClick: () => void }) => React.ReactNode);
  size?: 'lg' | 'md';
  width?: string;
  isError?: boolean;
  isLoading?: boolean;
  position?: 'left' | 'right' | 'top';
  isEmpty?: boolean;
  value: string | string[];
  onChange: (value: string | string[]) => void;
  children: React.ReactNode;
  className?: string;
}

const Item = ({
  children,
  // @ts-expect-error should not be used externally
  onItemClick,
  // @ts-expect-error should not be used externally
  selected,
  value,
  addonLeft,
  addonRight,
  addonRightMost,
  additionalInfo = '',
  disabled = false,
}: ItemProps) => (
  <button
    className={`w-full py-2 px-4 cursor-pointer disabled:bg-white disabled:opacity-30 disabled:cursor-not-allowed
  ${selected === value ? 'bg-grey-200' : 'hover:bg-grey-100'}`}
    onClick={onItemClick}
    disabled={disabled}
  >
    <div className="flex items-center justify-between gap-1">
      <div className="flex items-center gap-3">
        <div>{addonLeft}</div>
        <p>{children}</p>
        <div>{addonRight}</div>
      </div>
      <div>{addonRightMost}</div>
    </div>
    {additionalInfo && (
      <div className=" text-grey-700 flex text-left">{additionalInfo}</div>
    )}
  </button>
);

const Checkbox = ({
  children,
  // @ts-expect-error should not be used externally
  onCheckboxClick,
  // @ts-expect-error should not be used externally
  selected,
  value,
  addonRight,
  addonRightMost,
  additionalInfo = '',
  disabled = false,
}: CheckboxProps) => (
  <button
    className={`w-full py-2 px-4 cursor-pointer disabled:bg-white disabled:opacity-30 disabled:cursor-not-allowed
${selected?.includes(value) ? 'bg-red-50' : 'hover:bg-grey-100'}`}
    onClick={onCheckboxClick}
    disabled={disabled}
  >
    <div className="flex items-center justify-between gap-1">
      <div className="flex items-center gap-1">
        <div>
          <CheckboxComponent
            className="pointer-events-none"
            checked={selected?.includes(value)}
            onChange={() => { }}
          />
        </div>
        <p>{children}</p>
        <div>{addonRight}</div>
      </div>
      <div>{addonRightMost}</div>
    </div>
    {additionalInfo && (
      <div className=" text-grey-700 flex text-left">{additionalInfo}</div>
    )}
  </button>
);

function Dropdown({
  trigger,
  size = 'md',
  width = '',
  isError = false,
  isLoading = false,
  position = 'left',
  isEmpty = false,
  value,
  onChange,
  children,
  className = '',
}: DropdownProps) {
  const [open, setOpen] = useState(false);
  const dropdownRef = useOutsideClickDetect(open, undefined, () =>
    setOpen(false)
  );
  const pos = {
    left: 'left-0',
    right: 'right-0',
    top: '-top-full transform -translate-y-2/3',
  };

  const getClassesBySize = () => {
    switch (size) {
      case 'lg':
        return 'min-w-65 text-base';
      case 'md':
      default:
        return 'min-w-60 text-sm';
    }
  };

  const handleClick = (id: string, type: DropdownTypes) => {
    if (type === 'item') {
      if (id === value) return;
      onChange(id);
      setOpen(false);
      return;
    }
    if (!Array.isArray(value)) return;
    if (value?.includes(id)) onChange(value.filter((v) => v !== id));
    else onChange([...value, id]);
  };

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div>
        {typeof trigger === 'function' &&
          trigger({ onClick: () => setOpen((prev) => !prev) })}
        {typeof trigger === 'string' && (
          <button onClick={() => setOpen((prev) => !prev)}>{trigger}</button>
        )}
      </div>
      {open && (
        <div
          className={`absolute flex flex-col
     bg-white border border-grey-200 rounded-md shadow-md overflow-auto
    ${width || getClassesBySize()} ${pos[position]}
    `}
        >
          {isError && (
            <div className="text-grey-700 py-2 px-4">
              Something went wrong. Please try again later.
            </div>
          )}
          {!isError && isLoading && (
            <div className="text-grey-700 py-2 px-4">Loading...</div>
          )}
          {!isError && !isLoading && !!isEmpty && (
            <div className="text-grey-700 py-2 px-4">Nothing found</div>
          )}
          {!isError &&
            !isLoading &&
            !isEmpty &&
            React.Children.map(
              children as React.ReactElement,
              (child: React.ReactElement) =>
                React.cloneElement(child, {
                  selected: value,
                  onItemClick: () => handleClick(child.props.value, 'item'),
                  onCheckboxClick: () =>
                    handleClick(child.props.value, 'checkbox'),
                })
            )}
        </div>
      )}
    </div>
  );
}

Dropdown.Item = Item;
Dropdown.Checkbox = Checkbox;

export default Dropdown;
