import type React from 'react';

interface ThemeCardBaseProps {
  isSelected?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  noCursor?: boolean;
  height?: string;
  minWidth?: string;
}
export default function ThemeCardBase({
  isSelected = false,
  onClick,
  className = '',
  children,
  height = 'h-[178px]',
  minWidth = ''
}: ThemeCardBaseProps) {
  return (
    <button
      className={`${minWidth} ${className} ${isSelected ? 'border-red-500' : 'border-grey-200'} ${onClick ? '' : 'cursor-default'} ${height} bg-grey-0 border-1  grid place-items-center rounded-md overflow-hidden hover:bg-grey-100 transition-colors`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
