import type React from 'react';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import Tooltip from '@components/Presentation/atoms/Tooltip';

interface SettingBlockProps {
  title?: string;
  titleMargin?: 'sm' | 'md' | 'lg';
  itemGap?: 'sm' | 'md' | 'lg';
  hasBorder?: boolean;
  hasBorderTop?: boolean;
  hasBorderDown?: boolean;
  hasBg?: boolean;
  tooltip?: React.ReactNode;
  addPadding?: boolean;
  hasParentBg?: boolean;
  children: React.ReactNode;
  rightContent?: React.ReactNode;
}
function SettingBlock({
  title = '',
  titleMargin = 'md',
  itemGap = 'md',
  children,
  hasBorderTop = true,
  hasBorderDown = false,
  hasBg = false,
  tooltip = '',
  addPadding = true,
  hasParentBg = false,
  rightContent = null
}: SettingBlockProps) {
  const gap = {
    sm: 'gap-3',
    md: 'gap-4',
    lg: 'gap-6'
  };
  const margin = {
    sm: 'mb-3',
    md: 'mb-4',
    lg: 'mb-6'
  };
  return (
    <div
      className={`flex flex-col ${
        hasBorderTop ? 'border-t border-grey-100 pt-6' : ''
      } 
      ${hasBorderDown ? 'border-b border-grey-200 pt-6 pb-6' : ''} 
      ${hasBg ? 'border-1 border-grey-100 bg-grey-50 mx-3 rounded-md !p-2.5 mb-6' : ''} 
      ${addPadding ? 'p-6' : ''}
      ${hasParentBg ? '!p-0' : ''}
      `}
    >
      <div className='flex justify-between'>

        <h4 className={`text-sm font-semibold flex gap-2  ${margin[titleMargin]}`}>
          {title}
          {tooltip && (
            <Tooltip content={tooltip}>
              <HelpRoundedIcon
                fontSize="small"
                color="disabled"
              />
            </Tooltip>
          )}
        </h4>
        {rightContent && rightContent}
      </div>

      <div className={`flex flex-col  ${gap[itemGap]}`}>{children}</div>

    </div>
  );
}

export default SettingBlock;
