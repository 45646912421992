import { type SAFonts } from '@atoms/FontPicker';
import { type InteractionWithoutPDF, type InteractionColors } from '@components/Presentation/types';

import createOptimisedContext from '@lib/createOptimisedContext';

export type ThemeMenu = 'All' | InteractionWithoutPDF

export type ThemeConfig = {
  default: {
    bgColor: string;
    headingColor: string;
    textColor: string;
    textFontFamily: SAFonts;
    headingFontFamily: SAFonts;
    imageSrc: string | null;
    imageOpacity: number;
  }
  colors: InteractionColors;
  headingFontFamily: ThemeFontFamily;
  textFontFamily: TextFontFamily;
  images: {
    pollImageSrc: string | null;
    pollImageOpacity: number;
    pollWithOptionsImageSrc: string | null;
    pollWithOptionsImageOpacity: number;
    wcImageSrc: string | null;
    wcImageOpacity: number;
    mapImageSrc: string | null;
    mapImageOpacity: number;
    winningWheelImageSrc: string | null;
    winningWheelImageOpacity: number;
    ttImageSrc: string | null;
    ttImageOpacity: number;
    emojisEverywhereImageSrc: string | null;
    emojisEverywhereImageOpacity: number;
    talkingTilesImageSrc: string | null;
    talkingTilesImageOpacity: number;
    ffImageSrc: string | null;
    ffImageOpacity: number;
    linkLibraryImageSrc: string | null;
    linkLibraryImageOpacity: number;
    choiceCircleImageSrc: string | null;
    choiceCircleImageOpacity: number;
    quickQuestionImageSrc: string | null;
    quickQuestionImageOpacity: number;
    textTrackImageSrc: string | null;
    textTrackImageOpacity: number;
    ratingPollsImageSrc: string | null;
    ratingPollsImageOpacity: number;
    multipleChoiceImageSrc: string | null;
    multipleChoiceImageOpacity: number
  }
}

export type Theme = {
  id: string;
  name: string;
  is_editable: boolean;
  config: ThemeConfig;
}

const defaultColors: InteractionColors = {
  coCommentColor: '#000000',
  coUsernameColor: '#000000',
  coBgColor: '#F5F5F5',
  pollHeadingColor: '#FFFFFF',
  pollWithOptionsHeadingColor: '#FFFFFF',
  wcHeadingColor: '#FFFFFF',
  mapHeadingColor: '#FFFFFF',
  ratingPollsHeadingColor: '#FFFFFF',
  winningWheelHeadingColor: '#FFFFFF',
  choiceCircleHeadingColor: '#FFFFFF',
  ffHeadingColor: '#FFFFFF',
  quickQuestionHeadingColor: '#FFFFFF',
  linkLibraryHeadingColor: '#FFFFFF',
  emojisEverywhereHeadingColor: '#FFFFFF',
  textTrackHeadingColor: '#FFFFFF',
  talkingTilesHeadingColor: '#FFFFFF',
  ttHeadingColor: '#FFFFFF',
  pollLabelColor: '#FFFFFF',
  pollWithOptionsLabelColor: '#FFFFFF',
  pollChartColor: '#F97B5F',
  pollWithOptionsChartColor: '#F97B5F',
  pollBgColor: '#111111',
  pollWithOptionsBgColor: '#111111',
  wcWordColor: '#319795',
  wcBgColor: '#111111',
  mapWaterColor: '#B7CAE6',
  mapLandColor: '#FFFFFF',
  mapBgColor: '#000000',
  smallCircleColor: '#FF0064',
  midCircleColor: '#FF0064',
  bigCircleColor: '#FF0064',
  latestPointColor: '#9567F9',
  individualPointColor: '#FF0064',
  multipleColors: true,
  chatBubble1: '#8942DF',
  chatBubble2: '#56CB77',
  ttmultipleColors: true,
  ttBgColor: '#111111',
  ttTextColor1: '#000000',
  ttTextColor2: '#000000',
  emojisEverywhereBgColor: '#111111',
  textTrackWordColor: '#319795',
  textTrackBgColor: '#111111',
  textTrackMultipleColors: true,
  talkingTilesBgColor: '#111111',
  talkingTileBgColor1: '#8942DF',
  talkingTileBgColor2: '#56CB77',
  talkingTileBgColor3: '#E2A848',
  talkingTilesTextColor: '#111111',
  winningWheelBgColor: '#111111',
  quickQuestionBgColor: '#111111',
  quickQuestionTextColor: '#FFFFFF',
  linkLibraryBgColor: '#111111',
  linkLibraryTextColor: '#FFFFFF',
  choiceCircleBgColor: '#111111',
  ffBgColor: '#111111',
  ffTextColor: '#FFFFFF',
  ratingPollsLabelColor: '#FFFFFF',
  ratingPollsChartColor: '#F97B5F',
  ratingPollsBgColor: '#111111',
  multipleChoiceBgColor: '#111111',
  multipleChoiceChartColor: '#F97B5F',
  multipleChoiceLabelColor: '#FFFFFF',
  multipleChoiceHeadingColor: '#FFFFFF',
};

export type FontFamilyKey = 'mapHeadingFont' | 'pollWithOptionsHeadingFont' | 'pollHeadingFont' |
'wordCloudHeadingFont' | 'wheelHeadingFont' | 'emojisEverywhereHeadingFont' |
'talkingTilesHeadingFont' | 'transientThoughtHeadingFont' | 'newWheelHeadingFont' |
 'quickQuestionsHeadingFont' | 'linkLibraryHeadingFont' | 'textTrackHeadingFont' |
 'ratingPollsHeadingFont' | 'fantasticFansHeadingFont' | 'multipleChoiceHeadingFont';

export type ThemeFontFamily = Record<FontFamilyKey, SAFonts>;

const defaultFontFamily: ThemeFontFamily = {
  mapHeadingFont: 'Sans-Serif',
  pollWithOptionsHeadingFont: 'Sans-Serif',
  pollHeadingFont: 'Sans-Serif',
  wordCloudHeadingFont: 'Sans-Serif',
  wheelHeadingFont: 'Sans-Serif',
  emojisEverywhereHeadingFont: 'Sans-Serif',
  talkingTilesHeadingFont: 'Sans-Serif',
  transientThoughtHeadingFont: 'Sans-Serif',
  newWheelHeadingFont: 'Sans-Serif',
  quickQuestionsHeadingFont: 'Sans-Serif',
  linkLibraryHeadingFont: 'Sans-Serif',
  textTrackHeadingFont: 'Sans-Serif',
  ratingPollsHeadingFont: 'Sans-Serif',
  fantasticFansHeadingFont: 'Sans-Serif',
  multipleChoiceHeadingFont: 'Sans-Serif',
};

export type TextFontFamilyKey = 'pollWithOptionsFontStyle' | 'pollFontStyle' | 'wordCloudFontStyle' | 'transientThoughtFontStyle' | 'textTrackFontStyle' | 'fantasticFansFontStyle' | 'multipleChoiceFontStyle';
export type TextFontFamily = Record<TextFontFamilyKey, SAFonts>

const defaultTextFontFamily: TextFontFamily = {
  pollWithOptionsFontStyle: 'Sans-Serif',
  pollFontStyle: 'Sans-Serif',
  wordCloudFontStyle: 'Sans-Serif',
  transientThoughtFontStyle: 'Sans-Serif',
  textTrackFontStyle: 'Sans-Serif',
  fantasticFansFontStyle: 'Sans-Serif',
  multipleChoiceFontStyle: 'Sans-Serif',
};

export const defaultThemeConfig: ThemeConfig = {
  default: {
    bgColor: '#111111',
    headingColor: '#FFFFFF',
    textColor: '#FFFFFF',
    textFontFamily: 'Sans-Serif',
    headingFontFamily: 'Sans-Serif',
    imageOpacity: 100,
    imageSrc: null
  },
  colors: defaultColors,
  headingFontFamily: defaultFontFamily,
  textFontFamily: defaultTextFontFamily,
  images: {
    pollImageOpacity: 100,
    pollImageSrc: null,
    pollWithOptionsImageOpacity: 100,
    pollWithOptionsImageSrc: null,
    choiceCircleImageOpacity: 100,
    choiceCircleImageSrc: null,
    emojisEverywhereImageOpacity: 100,
    emojisEverywhereImageSrc: null,
    ffImageOpacity: 100,
    ffImageSrc: null,
    linkLibraryImageOpacity: 100,
    linkLibraryImageSrc: null,
    mapImageOpacity: 100,
    mapImageSrc: null,
    quickQuestionImageOpacity: 100,
    quickQuestionImageSrc: null,
    ratingPollsImageOpacity: 100,
    ratingPollsImageSrc: null,
    textTrackImageOpacity: 100,
    textTrackImageSrc: null,
    ttImageOpacity: 100,
    ttImageSrc: null,
    wcImageOpacity: 100,
    wcImageSrc: null,
    winningWheelImageOpacity: 100,
    winningWheelImageSrc: null,
    talkingTilesImageOpacity: 100,
    talkingTilesImageSrc: null,
    multipleChoiceImageSrc: null,
    multipleChoiceImageOpacity: 100,
  }
};

type ThemeCtx = {
    themeMenu: 'chooseTheme' | 'editTheme'
    selectedMenu: ThemeMenu;
    selectedTheme: Theme | null;
    themes: {
      userThemes?: Theme[];
      defaultThemes?: Theme[];
    }
  }

const {
  Provider: ThemeProvider,
  useStore: useThemeStore
} = createOptimisedContext<ThemeCtx>({
  themeMenu: 'chooseTheme',
  themes: {
    userThemes: [],
    defaultThemes: [],
  },
  selectedMenu: 'All',
  selectedTheme: null,
});

export { ThemeProvider, useThemeStore };
