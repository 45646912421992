import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const defaultContent = ' The sample image on the left is for reference. While changes below will not update it, they will apply to your presentation.';
interface BannerProps {
  open: boolean;
  onClose?: () => void;
  content?: string;
}
export default function Banner({
  open,
  onClose,
  content = defaultContent,
}: BannerProps) {
  if (!open) return null;
  return (
    <div className='flex items-start gap-3 bg-blue-50 mt-6 rounded-md border border-blue-500 p-4'>
      <InfoOutlinedIcon className='text-blue-500' fontSize='small' />
      <p className='text-sm'>
        {content}
      </p>

      {onClose &&
      <CloseRoundedIcon className='cursor-pointer' onClick={onClose} fontSize='small'/>
      }
    </div>
  );
}
