import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@atoms/new/modal';
import { GenAiMainContent } from './gen-ai-main-content';
import { GenAiResultFooter } from './gen-ai-result-footer';
import Button from '@atoms/new/button';
import { useEffect, useState } from 'react';
import { interactionsMap, type AutocompleteItem, type BlockParams } from '.';
import { useMutation } from '@tanstack/react-query';
import { useDebounce, usePrevious } from '@uidotdev/usehooks';
import { deleteAutoComplete, getAutoComplete } from '@services/generative-ai';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { type InteractionOptions } from '../types';
import { v4 as uuidv4 } from 'uuid';
import { getOpenAIQuestions } from '@services/openai.service';
import Badge from '@atoms/Badge';

interface GenerateFeatureSuggestionsModalProps {
  isOpen: boolean;
  onClose?: () => void;
  onOpenChange?: () => void;
  feature: keyof typeof interactionsMap;
  setQuestionTitle?: (title: string) => void;
  setOptions?: (options: any[]) => void;
}

export const GenerateFeatureSuggestionsModal = ({
  isOpen,
  onOpenChange,
  onClose = () => {},
  feature,
  setQuestionTitle,
  setOptions,
}: GenerateFeatureSuggestionsModalProps) => {
  const [topic, setTopic] = useState('');
  const [audience, setAudience] = useState('');
  const [result, setResult] = useState<BlockParams[]>([]);
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [page, setPage] = useState(1);

  const { data, mutate, isPending, isError, reset } = useMutation({
    mutationFn: () =>
      getOpenAIQuestions({
        count: 3,
        topic: query,
        page,
        audience,
        featureType: feature,
      }),
  });

  const fetchSlides = () => {
    setAutoComplete([]);
    setResult([]);
    setShowResult(true);
    mutate();
  };

  const [generateNewIsLoading, setGenerateNewIsLoading] = useState<any>({});
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    if (data) {
      if (data?.entity?.length === 0) return;

      if (!Array.isArray(data?.entity)) return;

      setResult(data?.entity);
      setSelectedBlocks([]);
    }
  }, [data]);

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const previousQuery = usePrevious(debouncedQuery);

  const [autocomplete, setAutoComplete] = useState<AutocompleteItem[]>([]);
  const [showAutoComplete, setShowAutoComplete] = useState(false);

  useEffect(() => {
    if (previousQuery?.includes(debouncedQuery)) return;
    if (!debouncedQuery) {
      setAutoComplete([]);
      return;
    }

    if (!showAutoComplete) return;

    getAutoComplete(debouncedQuery, feature)
      .then((res: any) => {
        if (res.status) {
          setAutoComplete(res.entity);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, [debouncedQuery, showAutoComplete, previousQuery]);

  const handleClose = () => {
    setPage(1);
    setResult([]);
    setAutoComplete([]);
    setShowResult(false);
    setAudience('');
    setSelectedBlocks([]);
    setQuery('');
    reset();
  };

  const { mutateAsync: handleDeleteAutoComplete } = useMutation({
    mutationFn: deleteAutoComplete,
  });

  const onDeleteAutoComplete = async (topic: string) => {
    try {
      setAutoComplete((prev) => prev.filter((i) => i.topic !== topic));
      await handleDeleteAutoComplete(topic);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Modal
      isKeyboardDismissDisabled
      scrollBehavior="inside"
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
        onClose();
      }}
      onOpenChange={onOpenChange}
    >
      <ModalContent
        style={{
          background: 'linear-gradient(180deg, #FFF 46%, #FBD4E2 100%)',
        }}
      >
        <ModalHeader>
          <div className="flex items-center gap-3 justify-between w-full">
            {showResult ? (
              <Button
                size="sm"
                kind="secondary"
                isIconOnly
                onClick={handleClose}
                startContent={<ArrowBackRoundedIcon fontSize="small" />}
              />
            ) : (
              <div />
            )}
            <div className='text-black flex items-center gap-3'>
              <p>Create {interactionsMap[feature]?.title} with AI</p>
              <Badge variant='warning'>beta</Badge>
            </div>
            <div />
          </div>
        </ModalHeader>
        <ModalBody className={`${showResult ? 'pt-0' : 'px-16 pt-16 pb-8'} scrollbar-hide`}>
          <GenAiMainContent
            onDeleteAutoComplete={onDeleteAutoComplete}
            selectionMode="single"
            isError={isError}
            setAudience={(value: string) => setAudience(prev => prev === value ? '' : value)}
            showResult={showResult}
            topic={topic}
            setTopic={setTopic}
            selectedBlocks={selectedBlocks}
            setSelectedBlocks={setSelectedBlocks}
            audience={audience}
            autocomplete={autocomplete}
            fetchSlides={fetchSlides}
            setPage={setPage}
            result={result}
            setGenerateNewIsLoading={setGenerateNewIsLoading}
            generateNewIsLoading={generateNewIsLoading}
            setResult={setResult}
            isPending={isPending}
            query={query}
            onQueryChange={(value) => {
              setQuery(value);
              setShowAutoComplete(true);
            }}
            showAutoComplete={showAutoComplete}
            setShowAutoComplete={setShowAutoComplete}
            onAutocompleteClick={(value) => {
              setTopic(value.topic);
              setQuery(value.topic);
              setAudience(value.audience);
              setShowAutoComplete(false);
              fetchSlides();
            }}
          />
        </ModalBody>
        {showResult && (
          <ModalFooter>
            <GenAiResultFooter
              selectedBlocks={selectedBlocks.length}
              onAddToSlide={() => {
                const selectedBlock = result.find(
                  (i) => i.title === selectedBlocks[0]
                );

                if (!selectedBlock) return;
                const options: InteractionOptions[] = [];

                if (selectedBlock.options) {
                  selectedBlock.options.forEach((option) => {
                    options.push({
                      body: option.title,
                      checked: option.checked,
                      id: uuidv4(),
                    });
                  });
                }

                setQuestionTitle?.(selectedBlock.title);
                setOptions?.(options);

                handleClose();
                onClose();
              }}
            />
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
