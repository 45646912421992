import React from 'react';

interface MaxLengthCounterProps {
  value: number
  threshold?: number
}
export default function MaxLengthCounter({ value, threshold = 40 }: MaxLengthCounterProps) {
  return (
    <p className={`${value <= threshold ? 'text-alert' : 'text-grey-400'}`}>
      {value}
    </p>
  );
}
